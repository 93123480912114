import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

// Function to generate dates between start and end date
const generateDateRange = (start, end) => {
  const dates = [];
  let currentDate = new Date(start);

  while (currentDate <= new Date(end)) {
    dates.push(new Date(currentDate).toISOString().split('T')[0]); // Format YYYY-MM-DD
    currentDate.setDate(currentDate.getDate() + 1);
  }
  return dates;
};

export const templateShiftExcel = (startDate, endDate, employeeData) => {
  if (!startDate || !endDate) {
    alert("Masukan tanggal terlebih dahulu");
    return;
  }

  const dateRange = generateDateRange(startDate, endDate);

  // Prepare header
  const headers = ['Employee ID', 'Employee Name', ...dateRange];

  // Prepare rows
  const rows = employeeData.map(employee => {
    return [employee.users_code, employee.name, ...Array(dateRange.length).fill('')]; // Empty values for each date
  });

  // Create worksheet and workbook
  const ws = XLSX.utils.aoa_to_sheet([headers, ...rows]);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Employees");

  // Generate and save Excel file
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
  saveAs(blob, 'Employee_Shift.xlsx');
};
