import { HashRouter, Route, Routes } from "react-router-dom";
import ProtectedLayout from "../Component/Main/ProtectedLayout";
import Login from '../Views/Auth/Login';
import Dashboard from "../Views/Dashboard/Dashboard";
import NotFound from "../Views/NotFound/NotFound";
import Employee from "../Views/Employee/Employee";
import RoleUser from "../Views/RoleUser/RoleUser";
import Profile from "../Views/Profile/Profile";
import Division from "../Views/Division/Division";
import Position from "../Views/Position/Position";
import Area from "../Views/Area/Area";
import Level from "../Views/Level/Level";
import SubmissionRegular from "../Views/SubmissionRegular/SubmissionRegular";
import Meeting from "../Views/Meeting/Meeting";
import Benefit from "../Views/Benefit/Benefit";
import LevelDetail from "../Views/LevelDetail/LevelDetail";
import BenefitForm from "../Views/Benefit/BenefitForm";
import Approval from "../Views/Approval/Approval";
import SubmissionTravel from "../Views/SubmissionTravel/SubmissionTravel";
import SubmissionTravelDetail from "../Views/SubmissionTravel/SubmissionTravelDetail";
import SubmissionRegularDetail from "../Views/SubmissionRegular/SubmissionRegularDetail";
import Organize from "../Views/Organize/Organize";
import Settlement from "../Views/Settlement/Settlement";
import SettlementDetail from "../Views/Settlement/SettlementDetail";
import ApprovalSettlement from "../Views/ApprovalSettlement/ApprovalSettlement";
import ChangePassword from "../Views/ChangePassword/ChangePassword";
import Shift from "../Views/Shift/Shift";
import Overtime from "../Views/Overtime/Overtime";
import LeavePaid from "../Views/LeavePaid/LeavePaid";
import LeaveType from "../Views/LeaveType/LeaveType";
import Attendance from "../Views/Attendance/Attendance";
import ApprovalOvertime from "../Views/ApprovalOvertime/ApprovalOvertime";
import ApprovalLeavePaid from "../Views/ApprovalLeavePaid/ApprovalLeavePaid";
import UserShift from "../Views/UserShift/UserShift";
import Schedule from "../Views/Schedule/Schedule";
import Kpi from "../Views/KPI/Kpi";
import UserSchedule from "../Views/UserSchedule/UserSchedule";

function Router({ history }) {
  return (
      <HashRouter history={history}>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<ProtectedLayout />} >
            <Route path="/" element={<Dashboard />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/perjalanandinas" element={<SubmissionTravel />} />
            <Route path="/detailperjalanandinas" element={<SubmissionTravelDetail />} />
            <Route path="/detailregular" element={<SubmissionRegularDetail />} />
            <Route path="/regular" element={<SubmissionRegular />} />
            <Route path="/employee" element={<Employee />} />
            <Route path="/access" element={<RoleUser />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/division" element={<Division />} />
            <Route path="/level" element={<Level />} />
            <Route path="/area" element={<Area />} />
            <Route path="/position" element={<Position />} />
            <Route path="/meeting" element={<Meeting />} />
            <Route path="/benefit" element={<Benefit />} />
            <Route path="/benefitform" element={<BenefitForm />} />
            <Route path="/leveldetail" element={<LevelDetail />} />
            <Route path="/approval" element={<Approval />} />
            <Route path="/approvalsettlement" element={<ApprovalSettlement />} />
            <Route path="/organize" element={<Organize />} />
            <Route path="/settlement" element={<Settlement />} />
            <Route path="/detailsettlement" element={<SettlementDetail />} />
            <Route path="/changepassword" element={<ChangePassword />} />
            <Route path="/shift" element={<Shift />} />
            <Route path="/overtime" element={<Overtime />} />
            <Route path="/leavepaid" element={<LeavePaid />} />
            <Route path="/leavetype" element={<LeaveType />} />
            <Route path="/attendance" element={<Attendance />} />
            <Route path="/approvalovertime" element={<ApprovalOvertime />} />
            <Route path="/approvalleavepaid" element={<ApprovalLeavePaid />} />
            <Route path="/usershift" element={<UserShift />} />
            <Route path="/schedule" element={<Schedule />} />
            <Route path="/kpi" element={<Kpi />} />
            <Route path="/userschedule" element={<UserSchedule />} />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
        </HashRouter>
  );
}


export default Router;