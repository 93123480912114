
import { Alert, Button, Col, DatePicker, Divider, message, Modal, Row, Spin, Table, Upload } from 'antd';
import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import { apiCall } from '../../API/RestApi';
import { useSelector } from 'react-redux';
import Search from 'antd/es/input/Search';
import { UploadOutlined } from '@ant-design/icons';
import moment from 'moment';
import { templateShiftExcel } from '../../Utils/exportShiftExcel';
const { RangePicker } = DatePicker;

function UserShift() {
    const token = useSelector((state) => state.user.token);
    const [dates, setDates] = useState(moment().format('YYYY-MM-DD'));
    const [data, setData] = useState([]);
    const [users, setUsers] = useState([]);
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(false);
    const [isModal, setIsModal] = useState(false);
    const [fileList, setFileList] = useState([]);

    const [rangeDate, setRangeDate] = useState([]);
    const [page, setPage] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });
    const [isAlert, setIsAlert] = useState(false);
    const [textAlert, setTextAlert] = useState(false);

    // //Parameter
    const titlePage = 'Data Shift Karyawan';
    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            key: "no",
            render: (id, record, index) => { ++index; return (page.pagination.current - 1) * 10 + index },
        },
        {
            title: 'Kode Karyawan',
            dataIndex: 'users_code',
            key: "users_code",
            render: (text, record) => {
                if (record.users !== null)
                    return record.users.users_code;
                else
                    return "-";
            }
        },
        {
            title: 'Nama Karyawan',
            dataIndex: 'name',
            key: "name",
            render: (text, record) => {
                if (record.users !== null)
                    return record.users.name;
                else
                    return "-";
            }
        },
        {
            title: 'Nama Karyawan',
            dataIndex: 'name',
            key: "name",
            render: (text, record) => {
                if (record.users !== null)
                    return record.users.name;
                else
                    return "-";
            }
        },
        {
            title: 'Shift',
            dataIndex: 'tgl',
            key: "tgl",
            render: (text, record) => {
                if (record.shift !== null)
                    return record.shift.name;
                else
                    return "-";
            }
        },
        {
            title: 'Tanggal',
            dataIndex: 'tgl',
            key: "tgl",
            render: (text, record) => {
                if (record.date !== "")
                    return moment(record.date).format('DD-MM-YYYY')
                else
                    return "-";
            }
        },
        {
            title: 'Clock In',
            dataIndex: 'clockin',
            key: "clockin",
            render: (text, record) => {
                if (record.shift !== null)
                    return record.shift.time_in
                else
                    return "-";
            }
        },
        {
            title: 'Clock Out',
            dataIndex: 'clockout',
            key: "clockout",
            render: (text, record) => {
                if (record.shift !== null)
                    return record.shift.time_out
                else
                    return "-";
            }
        },
    ];

    useEffect(() => {
        getApi("GET", '/usershift?date=' + dates + '&search=' + search + '&page=' + page.pagination.current, 'usershift');
        if(users.length === 0){
            getApi("GET", '/users?nopage=true', 'users');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(page), isAlert, dates]);

    const getApi = (method, url, type) => {
        setLoading(true);

        const dataReq = {
            method: method,
            url: url,
            data: {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            }
        };
        apiCall(dataReq).then((res) => {
            if (res.status === "success") {
                switch (type) {
                    case 'usershift':
                        setData(res.data.data);
                        setPage({
                            ...page,
                            pagination: {
                                ...page.pagination,
                                total: res.data.total_data
                            }
                        });
                        setLoading(false);
                        break;
                    case 'users':
                        setUsers(res.data.data);
                        setLoading(false);
                        break;

                    default:
                        break;
                }
            } else {
                setLoading(false);
            }
        }).catch((e) => {
            setLoading(false);
            console.log(e.message);
        })
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setPage({
            pagination,
            filters,
            ...sorter,
        });
        if (pagination.pageSize !== page.pagination?.pageSize) {
            setData([]);
        }
    };


    const searchData = (value) => {
        setSearch(value);
        setPage({
            pagination: {
                current: 1,
                pageSize: 10,
            },
        });
    }

    const closeModal = () => {
        setIsModal(false);
    }


    const props = {
        onRemove: file => {
            setFileList(prev => prev.filter(item => item.uid !== file.uid));
        },
        beforeUpload: file => {
            setFileList([file]); // Set the file
            return false; // Prevent automatic upload
        },
        fileList,
    };

    const postUpload = (method, url, values) => {
        setLoading(true);
        setIsAlert(false);
        const dataReq = {
            method: method,
            url: url,
            data: {
                data: values,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: 'Bearer ' + token
                }
            }
        };
        apiCall(dataReq).then((res) => {
            setLoading(false);
            setIsAlert(true);
            setTextAlert(res.message);
            message.success('File uploaded successfully!');
            setFileList([]); // Clear the file list after successful upload
            closeModal(); // Close the modal if any
        }).catch((e) => {
            setLoading(false);
        })
    }

    const handleUpload = () => {
        setLoading(true);
        const formData = new FormData();
        formData.append('file', fileList[0]);
        postUpload('POST', '/upload/shift', formData);
    };

    const onChangeDate = (date, dateString) => {
        if (dateString) {
            setDates(dateString);
        } else {
            setDates(moment().format('YYYY-MM-DD'));
        }
    };

    return (
        <div>
            <Helmet>
                <title>{titlePage}</title>
            </Helmet>
            {isAlert && <Alert message={textAlert} type="success" banner closable afterClose={() => setIsAlert(false)} />}
            <h3>Shift Karyawan</h3>
            <Row>
                <Col span={16}>
                    <DatePicker onChange={onChangeDate} format={'YYYY-MM-DD'} placeholder='Pilih Tanggal' />
                    <Search
                        placeholder="Pencarian"
                        allowClear
                        enterButton="Search"
                        style={{ marginLeft: 10, width: '40%' }}
                        size="medium"
                        onSearch={searchData}
                    />
                </Col>
                <Col span={8} style={{ display: 'flex', justifyContent: 'flex-end' }} >
                    <Button style={{ marginBottom: 10 }} type="primary" onClick={() => setIsModal(true)}>
                        <b style={{ marginLeft: 5 }}>Buat Shift</b>
                    </Button>
                </Col>
            </Row>
            <Table
                size="middle"
                columns={columns}
                dataSource={data}
                pagination={page.pagination}
                loading={loading}
                onChange={handleTableChange}
            />
            <Modal title="Form Shift" open={isModal} footer={null} onCancel={closeModal}>
                <div style={{ marginBottom: 20 }}>
                    <h4>Download Template</h4>
                    <p>Pilih Tanggal</p>
                    <RangePicker
                        style={{ width: 300, marginBottom: 10 }}
                        onChange={(dates) => setRangeDate(dates)}
                    />
                    <Button
                        type="primary"
                        onClick={() => templateShiftExcel(rangeDate[0], rangeDate[1], users)}
                        style={{ marginBottom: 10, marginLeft: 10 }}
                    >
                        <b>Download</b>
                    </Button>
                </div>
                <Divider />
                <div>
                    <h4>Upload Data Shift</h4>
                    <Row>
                        <Upload {...props} maxCount={1} disabled={loading}>
                            <Button
                                icon={<UploadOutlined />}
                                disabled={loading || fileList.length > 0} // Disable when uploading or file is selected
                            >
                                {loading ? 'Uploading...' : 'Import Data Shift'}
                            </Button>
                        </Upload>
                        <Button
                            type="primary"
                            disabled={loading}
                            onClick={() => handleUpload(rangeDate[0], rangeDate[1], users)}
                            style={{ marginBottom: 10, marginLeft: 10 }}
                        >
                            {loading ? <Spin /> : <b>Upload</b>}
                        </Button>
                    </Row>

                </div>
            </Modal>
        </div>
    );
}


export default UserShift;