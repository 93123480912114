
import { Alert, Button, Checkbox, Col, DatePicker, Divider, Form, Input, message, Modal, Row, Select, Spin, Table, Upload } from 'antd';
import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import { EditOutlined, DeleteOutlined, PlusOutlined, UploadOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { apiCall } from '../../API/RestApi';
import { modalError } from '../../Component/Modal/ModalLayout';
import { useSelector } from 'react-redux';
import Search from 'antd/es/input/Search';
import moment from 'moment';
import dayjs from 'dayjs';
const { Option } = Select;


function Schedule() {
    const token = useSelector((state) => state.user.token);
    const [data, setData] = useState([]);
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(false);
    const [isModal, setIsModal] = useState(false);
    const [isModalExcel, setIsModalExcel] = useState(false);
    const [dataModal, setdataModal] = useState(null);
    const [fileList, setFileList] = useState([]);
    const [shift, setShift] = useState([]);
    const daysOfWeek = [
        { value: "Monday", label: "Senin" },
        { value: "Tuesday", label: "Selasa" },
        { value: "Wednesday", label: "Rabu" },
        { value: "Thursday", label: "Kamis" },
        { value: "Friday", label: "Jumat" },
        { value: "Saturday", label: "Sabtu" },
        { value: "Sunday", label: "Minggu" },
    ];

    const [form] = Form.useForm();
    const [page, setPage] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });
    const [isAlert, setIsAlert] = useState(false);
    const [textAlert, setTextAlert] = useState(false);

    // //Parameter
    const titlePage = 'Data Jadwal';
    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            key: "no",
            render: (id, record, index) => { ++index; return (page.pagination.current - 1) * 10 + index },
        },
        {
            title: 'Nama Jadwal',
            dataIndex: 'schedule_name',
            key: "schedule_name",
        },
        {
            title: 'Hari Efektif',
            dataIndex: 'effective_day',
            key: "effective_day",
            render: (text, record) => {
                if (record.effective_day !== "")
                    return (<p>{moment(record.effective_day).format('YYYY-MM-DD')}</p>);
                else
                    return "-";
            }
        },
        {
            title: 'Tanggal Buat',
            dataIndex: 'created_at',
            key: "created_at",
            render: (text, record) => {
                if (record.created_at !== "")
                    return (<p>{moment(record.created_at).format('YYYY-MM-DD HH:mm:ss')}</p>);
                else
                    return "-";
            }
        },
        {
            title: 'Tanggal Ubah',
            dataIndex: 'updated_at',
            key: "updated_at",
            render: (text, record) => {
                if (record.updated_at !== "")
                    return (<p>{moment(record.updated_at).format('YYYY-MM-DD HH:mm:ss')}</p>);
                else
                    return "-";
            }
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => {
                return (
                    <>
                        <Button type="primary" style={{ marginRight: 10 }} onClick={() => openDetailSchedule(record)}>
                            <EditOutlined />
                            <b style={{ marginLeft: 5 }}>Ubah</b>
                        </Button>
                        <Button type="primary" danger onClick={() => modalDeleteData(record)}>
                            <DeleteOutlined />
                            <b style={{ marginLeft: 5 }}>Hapus</b>
                        </Button>
                    </>
                );
            },
        },
    ];

    useEffect(() => {
        getApi("GET", '/schedule?search=' + search + '&page=' + page.pagination.current, 'schedule');

        if (shift.length === 0) {
            getApi('GET', '/shift?nopage=true', 'shift');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(page), isAlert]);

    const getApi = (method, url, type) => {
        setLoading(true);

        const dataReq = {
            method: method,
            url: url,
            data: {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            }
        };
        apiCall(dataReq).then((res) => {
            if (res.status === "success") {
                switch (type) {
                    case 'detail':
                        openModal('edit', res.data)
                        setLoading(false);
                    break;
                    case 'shift':
                        var u = [];
                        for (let i = 0; i < res.data.data.length; i++) {
                            var item = res.data.data[i];
                            u.push({
                                value: item.shift_id,
                                label: item.name
                            });
                        }
                        setShift(u);
                        setLoading(false);
                        break;
                    case 'schedule':
                        setData(res.data.data);
                        setPage({
                            ...page,
                            pagination: {
                                ...page.pagination,
                                total: res.data.total_data
                            }
                        });
                        setLoading(false);
                        break;

                    default:
                        setLoading(false);
                        break;
                }
            } else {
                setLoading(false);
            }
        }).catch((e) => {
            setLoading(false);
            console.log(e.message);
        })
    }

    const postApi = async (method, url, values) => {
        setLoading(true);
        setIsAlert(false);
        const dataReq = {
            method: method,
            url: url,
            data: {
                data: values,
                headers: {
                    Authorization: 'Bearer ' + token
                }
            }
        };
        await apiCall(dataReq).then((res) => {
            if (res.status === "success") {
                setdataModal(null);
                setLoading(false);
                setIsAlert(true);
                setTextAlert(res.message);
                closeModal();
            } else {
                setLoading(false);
                modalError(res.message);
            }
        }).catch((e) => {
            setLoading(false);
            console.log(e.message);
        })
    }

    const modalDeleteData = (v) => {
        Modal.confirm({
            title: 'NOTICE',
            content: 'Anda ingin menghapus data ini?',
            onOk: () => postApi('DELETE', '/schedule/' + v.schedule_id, null),
        });
    }

    const openModal = async (type, data) => {
        if (type === 'edit') {
            setdataModal(data)
            form.setFieldsValue({
                schedule_name: data.schedule_name,
                effective_day: dayjs(data.effective_day, 'YYYY-MM-DD'),
                shift_day: data.shift_day.map(day => ({
                    shift_id: day.shift_id,
                    day_name: day.day_name,
                    dayoff: day.dayoff,
                })),
            })
            setIsModal(true);
        } else {
            setIsModal(true);
        }
    }

    const onFinish = (ev) => {
        ev.effective_day = ev.effective_day.format('YYYY-MM-DD');
        if (dataModal != null) {
            postApi("PUT", '/schedule/' + dataModal.schedule_id, ev);
        } else {
            postApi("POST", '/schedule', ev);
        }
    }


    const closeModal = () => {
        setIsModalExcel(false);
        setdataModal(null);
        setIsModal(false);
        form.resetFields();
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setPage({
            pagination,
            filters,
            ...sorter,
        });
        if (pagination.pageSize !== page.pagination?.pageSize) {
            setData([]);
        }
    };


    const searchData = (value) => {
        setSearch(value);
        setPage({
            pagination: {
                current: 1,
                pageSize: 10,
            },
        });
    }


    const props = {
        onRemove: file => {
            setFileList(prev => prev.filter(item => item.uid !== file.uid));
        },
        beforeUpload: file => {
            setFileList([file]); // Set the file
            return false; // Prevent automatic upload
        },
        fileList,
    };

    const postUpload = (method, url, values) => {
        setLoading(true);
        setIsAlert(false);
        const dataReq = {
            method: method,
            url: url,
            data: {
                data: values,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: 'Bearer ' + token
                }
            }
        };
        apiCall(dataReq).then((res) => {
            setLoading(false);
            setIsAlert(true);
            setTextAlert(res.message);
            message.success('File uploaded successfully!');
            setFileList([]); // Clear the file list after successful upload
            closeModal(); // Close the modal if any
        }).catch((e) => {
            setLoading(false);
        })
    }

    const handleUpload = () => {
        setLoading(true);
        const formData = new FormData();
        formData.append('file', fileList[0]);
        postUpload('POST', '/upload/shiftday', formData);
    };

    const getSelectedDays = () => {
        const shiftDays = form.getFieldValue('shift_day') || [];
        return shiftDays.map(day => day?.day_name).filter(Boolean);
    };

    const openDetailSchedule = (record) => {
        getApi('GET', '/schedule/'+record.schedule_id, 'detail');
    }

    return (
        <div>
            <Helmet>
                <title>{titlePage}</title>
            </Helmet>
            {isAlert && <Alert message={textAlert} type="success" banner closable afterClose={() => setIsAlert(false)} />}
            <h3>Jadwal</h3>
            <Row>
                <Col span={16}>
                    <Button style={{ marginBottom: 10 }} type="default" onClick={() => setIsModalExcel(true)}>
                        <b style={{ marginLeft: 5 }}>Import Excel</b>
                    </Button>
                    <Search
                        placeholder="Pencarian"
                        allowClear
                        style={{ marginLeft: 10, width: '40%' }}
                        enterButton="Search"
                        size="medium"
                        onSearch={searchData}
                    />
                </Col>
                <Col span={8} style={{ display: 'flex', justifyContent: 'flex-end' }} >
                    <Button style={{ marginBottom: 10 }} type="primary" onClick={() => { openModal('add', null); }}>
                        <PlusOutlined />
                        <b style={{ marginLeft: 5 }}>Tambah</b>
                    </Button>
                </Col>
            </Row>
            <Table
                size="middle"
                columns={columns}
                dataSource={data}
                pagination={page.pagination}
                loading={loading}
                onChange={handleTableChange}
            />
            <Modal
                title="Data Jadwal"
                width={800}
                open={isModal}
                onOk={form.submit}
                onCancel={() => closeModal()}
                okText="Simpan"
            >
                <Form form={form} name="form" layout="vertical" onFinish={onFinish}>
                    <Form.Item
                        name="schedule_name"
                        label="Nama Jadwal"
                        rules={[
                            {
                                required: true,
                                message: 'Nama Jadwal kosong!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        name="effective_day"
                        label="Tanggal Efektif"
                        rules={[
                            {
                                required: true,
                                message: 'Tanggal Efektif kosong!',
                            },
                        ]}
                    >
                        <DatePicker style={{ width: '100%' }} format="YYYY-MM-DD" />
                    </Form.Item>
                    <Divider />
                    <Form.List name="shift_day">
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map(({ key, name, fieldKey, ...restField }) => {
                                    const selectedDays = getSelectedDays();
                                    return (
                                        <Row key={key} gutter={16} align="middle">
                                            <Col span={8}>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'shift_id']}
                                                    fieldKey={[fieldKey, 'shift_id']}
                                                    label="Shift"
                                                    rules={[{ required: true, message: 'Shift kosong!' }]}
                                                >
                                                    <Select
                                                        showSearch
                                                        placeholder="Pilih Shift"
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input)}
                                                        filterSort={(optionA, optionB) =>
                                                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                                        }
                                                        options={shift}
                                                    />
                                                </Form.Item>
                                            </Col>

                                            <Col span={8}>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'day_name']}
                                                    fieldKey={[fieldKey, 'day_name']}
                                                    label="Nama Hari"
                                                    rules={[{ required: true, message: 'Nama Hari kosong!' }]}
                                                >
                                                    <Select placeholder="Pilih Hari">
                                                        {daysOfWeek.map(day => (
                                                            <Option
                                                                key={day.value}
                                                                value={day.value}
                                                                disabled={selectedDays.includes(day.value)}
                                                            >
                                                                {day.label}
                                                            </Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                            </Col>

                                            <Col span={6}>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'dayoff']}
                                                    fieldKey={[fieldKey, 'dayoff']}
                                                    label="Libur"
                                                    valuePropName="checked"
                                                >
                                                    <Checkbox>Libur</Checkbox>
                                                </Form.Item>
                                            </Col>

                                            <Col span={2}>
                                                <MinusCircleOutlined
                                                    style={{ fontSize: '24px', color: 'red' }}
                                                    onClick={() => remove(name)}
                                                />
                                            </Col>
                                        </Row>
                                    )
                                })}

                                <Form.Item>
                                    <Button
                                        type="dashed"
                                        onClick={() => add()}
                                        block
                                        icon={<PlusOutlined />}
                                    >
                                        Tambah Shift
                                    </Button>
                                </Form.Item>
                            </>
                        )}
                    </Form.List>
                </Form>
            </Modal>


            <Modal title="Form Excel" open={isModalExcel} footer={null} onCancel={closeModal}>
                {/* <div style={{ marginBottom: 20 }}>
                    <h4>Download Template</h4>
                    <p>Pilih Tanggal</p>
                    <RangePicker
                        style={{ width: 300, marginBottom: 10 }}
                        onChange={(dates) => setRangeDate(dates)}
                    />
                    <Button
                        type="primary"
                        onClick={() => templateShiftExcel(rangeDate[0], rangeDate[1], users)}
                        style={{ marginBottom: 10, marginLeft: 10 }}
                    >
                        <b>Download</b>
                    </Button>
                </div> */}
                {/* <Divider /> */}
                <div>
                    <h4>Upload Data Jadwal</h4>
                    <Row>
                        <Upload {...props} maxCount={1} disabled={loading}>
                            <Button
                                icon={<UploadOutlined />}
                                disabled={loading || fileList.length > 0} // Disable when uploading or file is selected
                            >
                                {loading ? 'Uploading...' : 'Import Data Jadwal'}
                            </Button>
                        </Upload>
                        <Button
                            type="primary"
                            disabled={loading}
                            onClick={() => handleUpload()}
                            style={{ marginBottom: 10, marginLeft: 10 }}
                        >
                            {loading ? <Spin /> : <b>Upload</b>}
                        </Button>
                    </Row>

                </div>
            </Modal>
        </div>
    );
}


export default Schedule;