
import { Alert, Col, DatePicker, Divider, Form, Input, Modal, Row, Table } from 'antd';
import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import { apiCall } from '../../API/RestApi';
import { modalError } from '../../Component/Modal/ModalLayout';
import { useSelector } from 'react-redux';
import Search from 'antd/es/input/Search';
import moment from 'moment';

function Attendance() {
    const token = useSelector((state) => state.user.token);
    const [data, setData] = useState([]);
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(false);
    const [isModal, setIsModal] = useState(false);
    const [dataModal, setdataModal] = useState(null);
    const [date, setDate] = useState();

    const [total_absent, set_total_absent] = useState(0);
    const [total_check_in, set_total_check_in] = useState(0);
    const [total_check_out, set_total_check_out] = useState(0);
    const [total_no_check_in, set_total_no_check_in] = useState(0);
    const [total_no_check_out, set_total_no_check_out] = useState(0);
    const [on_time, set_on_time] = useState(0);
    const [total_late, set_total_late] = useState(0);

    const [form] = Form.useForm();
    const [page, setPage] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });
    const [isAlert, setIsAlert] = useState(false);
    const [textAlert, setTextAlert] = useState(false);

    // //Parameter
    const titlePage = 'Data Absensi';
    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            key: "no",
            render: (id, record, index) => { ++index; return (page.pagination.current - 1) * 10 + index },
        },
        {
            title: 'Kode User',
            dataIndex: 'users_code',
            key: "users_code",
            render: (text, record) => {
                return record.users_code;
            }
        },
        {
            title: 'Nama',
            dataIndex: 'name',
            key: "name",
            render: (text, record) => {
                if (record.name !== null)
                    return record.name;
                else
                    return '-';
            }
        },
        {
            title: 'Jadwal',
            dataIndex: 'schedule',
            key: "schedule",
            render: (text, record) => {
                if (record.user_shift.length > 0)
                    return record.user_shift[0].shift.name;
                else
                    if (record.schedule !== null)
                        if (record.schedule.shift_day.length > 0)
                            return record.schedule.shift_day[0].shift.name;
                        else
                            return "-";
                    else
                        return "-";
            }
        },
        {
            title: 'Jadwal Masuk',
            dataIndex: 'time_in',
            key: "time_in",
            render: (text, record) => {
                if (record.user_shift.length > 0)
                    return record.user_shift[0].shift.time_out;
                else
                    if (record.schedule !== null)
                        if (record.schedule.shift_day.length > 0)
                            return record.schedule.shift_day[0].shift.time_in;
                        else
                            return "-";
                    else
                        return "-";
            }
        },
        {
            title: 'Jadwal Keluar',
            dataIndex: 'time_out',
            key: "time_out",
            render: (text, record) => {
                if (record.user_shift.length > 0)
                    return record.user_shift[0].shift.time_out;
                else
                    if (record.schedule !== null)
                        if (record.schedule.shift_day.length > 0)
                            return record.schedule.shift_day[0].shift.time_out;
                        else
                            return "-";
                    else
                        return "-";
            }
        },
        {
            title: 'Clock In',
            dataIndex: 'clockin',
            key: "clockin",
            render: (text, record) => {
                if (record.attendance.length > 0) {
                    const { timeIn } = getTimeInAndOut(record.attendance);
                    if (timeIn)
                        return timeIn;
                    else
                        return "-";
                } else
                    return "-";
            }
        },
        {
            title: 'Clock Out',
            dataIndex: 'clockout',
            key: "clockout",
            render: (text, record) => {
                if (record.attendance.length > 0) {
                    const { timeOut } = getTimeInAndOut(record.attendance);
                    if (timeOut)
                        return timeOut;
                    else
                        return "-";
                } else
                    return "-";
            }
        },
        {
            title: 'Lembur',
            dataIndex: 'overtime',
            key: "overtime",
            render: (text, record) => {
                if (record.attendance.length > 0) {
                    const { timeIn } = getTimeInAndOut(record.attendance);
                    if (timeIn)
                        return timeIn;
                    else
                        return "-";
                } else
                    return "-";
            }
        },
        {
            title: 'Istirahat',
            dataIndex: 'break',
            key: "break",
            render: (text, record) => {
                if (record.attendance.length > 0) {
                    const { timeOut } = getTimeInAndOut(record.attendance);
                    if (timeOut)
                        return timeOut;
                    else
                        return "-";
                } else
                    return "-";
            }
        },
        // {
        //     title: 'Action',
        //     dataIndex: 'action',
        //     key: 'action',
        //     render: (text, record) => {
        //         return (
        //             <>
        //                 <Button type="primary" style={{ marginRight: 10 }} onClick={() => openModal('edit', record)}>
        //                     <EditOutlined />
        //                     <b style={{ marginLeft: 5 }}>Ubah</b>
        //                 </Button>
        //                 <Button type="primary" danger onClick={() => modalDeleteData(record)}>
        //                     <DeleteOutlined />
        //                     <b style={{ marginLeft: 5 }}>Hapus</b>
        //                 </Button>
        //             </>
        //         );
        //     },
        // },
    ];

    useEffect(() => {
        var dates = moment().format('YYYY-MM-DD');
        // if(date){
        //     dates = date
        // }
        getApi("GET", '/totalattendanceadmin?date=' + dates, 'total_attendance');
        getApi("GET", '/attendanceadmin?date=' + dates + '&search=' + search + '&page=' + page.pagination.current, 'attendance');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(page), isAlert]);

    function getTimeInAndOut(attendance) {
        let timeIn = null;
        let timeOut = null;

        attendance.forEach(att => {
            const createdAt = moment(att.created_at); 
            if (att.status === "checkin") {
                if (timeIn === null || createdAt.isBefore(moment(timeIn))) {
                    timeIn = createdAt.format('HH:mm:ss'); 
                }
            }
            if (att.status === "checkout") {
                if (timeOut === null || createdAt.isAfter(moment(timeOut))) {
                    timeOut = createdAt.format('HH:mm:ss');
                }
            }
        });

        return { timeIn, timeOut };
    }

    const getApi = (method, url, type) => {
        setLoading(true);

        const dataReq = {
            method: method,
            url: url,
            data: {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            }
        };
        apiCall(dataReq).then((res) => {
            if (res.status === "success") {
                switch (type) {
                    case 'attendance':
                        setData(res.data.data);
                        setPage({
                            ...page,
                            pagination: {
                                ...page.pagination,
                                total: res.data.total_data
                            }
                        });
                        setLoading(false);
                        break;
                    case 'total_attendance':
                        set_total_absent(res.data.absent);
                        set_total_check_in(res.data.check_in);
                        set_total_check_out(res.data.check_out);
                        set_total_late(res.data.late);
                        set_total_no_check_in(res.data.no_checkin);
                        set_total_no_check_out(res.data.no_clockout);
                        set_on_time(res.data.on_time);
                        setLoading(false);
                        break;

                    default:
                        setLoading(false);
                        break;
                }
            } else {
                setLoading(false);
            }
        }).catch((e) => {
            setLoading(false);
            console.log(e.message);
        })
    }

    const postApi = async (method, url, values) => {
        setLoading(true);
        setIsAlert(false);
        const dataReq = {
            method: method,
            url: url,
            data: {
                data: values,
                headers: {
                    Authorization: 'Bearer ' + token
                }
            }
        };
        await apiCall(dataReq).then((res) => {
            if (res.status === "success") {
                setdataModal(null);
                setLoading(false);
                setIsAlert(true);
                setTextAlert(res.message);
                closeModal();
            } else {
                setLoading(false);
                modalError(res.message);
            }
        }).catch((e) => {
            setLoading(false);
            console.log(e.message);
        })
    }

    const modalDeleteData = (v) => {
        Modal.confirm({
            title: 'NOTICE',
            content: 'Anda ingin menghapus data ini?',
            onOk: () => postApi('DELETE', '/attendance/' + v.attendance_id, null),
        });
    }

    const openModal = async (type, data) => {
        if (type === 'edit') {
            setdataModal(data)
            form.setFieldsValue({
                name: data.name,
                desc: data.desc,
            })
            setIsModal(true);
        } else {
            setIsModal(true);
        }
    }

    const onFinish = (ev) => {
        if (dataModal != null) {
            postApi("PUT", '/attendance/' + dataModal.attendance_id, ev);
        } else {
            postApi("POST", '/attendance', ev);
        }
    }


    const closeModal = () => {
        setdataModal(null);
        setIsModal(false);
        form.resetFields();
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setPage({
            pagination,
            filters,
            ...sorter,
        });
        if (pagination.pageSize !== page.pagination?.pageSize) {
            setData([]);
        }
    };


    const searchData = (value) => {
        setSearch(value);
        setPage({
            pagination: {
                current: 1,
                pageSize: 10,
            },
        });
    }

    const onChangeDate = (date, dateString) => {
        console.log(date, dateString);
    };

    return (
        <div>
            <Helmet>
                <title>{titlePage}</title>
            </Helmet>
            {isAlert && <Alert message={textAlert} type="success" banner closable afterClose={() => setIsAlert(false)} />}
            <h3>Data Absensi</h3>
            <Row>
                <Col span={16}>
                    <DatePicker onChange={onChangeDate} format={'YYYY-MM-DD'} placeholder='Pilih Tanggal' />
                    <Search
                        placeholder="Pencarian"
                        allowClear
                        style={{ marginLeft: 10, width: '40%' }}
                        enterButton="Search"
                        size="medium"
                        onSearch={searchData}
                    />
                </Col>
                <Col span={8} style={{ display: 'flex', justifyContent: 'flex-end' }} >
                    {/* <Button style={{ marginBottom: 10 }} type="primary" onClick={() => { openModal('add', null); }}>
                        <PlusOutlined />
                        <b style={{ marginLeft: 5 }}>Tambah</b>
                    </Button> */}
                </Col>
            </Row>
            <Row style={{ border: '1px solid #F2F2F2', marginBottom: 10, borderRadius: 10, marginTop: 10 }}>
                <Col span={8}>
                    <h4 style={{ textAlign: "center", margin: 0, padding: 0, marginTop: 10 }}>Kehadiran</h4>
                    <Row>
                        <Col span={8}>
                            <div style={{
                                padding: 10,
                                marginRight: 5,
                                textAlign: 'center'
                            }}>
                                <b style={{ color: '#4682B4', fontSize: 18 }}>{total_check_in}</b>
                                <div style={{ color: "#444444" }}>Clock In</div>
                            </div>
                        </Col>
                        <Col span={8}>
                            <div style={{
                                padding: 10,
                                marginRight: 5,
                                textAlign: 'center'
                            }}>
                                <b style={{ color: '#4682B4', fontSize: 18 }}>{total_check_out}</b>
                                <div style={{ color: "#444444" }}>Clock Out</div>
                            </div>
                        </Col>
                        <Col span={8}>
                            <div style={{
                                padding: 10,
                                marginRight: 5,
                                textAlign: 'center'
                            }}>
                                <b style={{ color: '#4682B4', fontSize: 18 }}>{total_check_in}</b>
                                <div style={{ color: "#444444" }}>Terlambat</div>
                            </div>
                        </Col>
                    </Row>
                </Col>
                < Divider
                    type="vertical" style={{ height: "100px", backgroundColor: "#F2F2F2" }}
                />
                <Col span={8}>
                    <h4 style={{ textAlign: "center", margin: 0, padding: 0, marginTop: 10 }}>Tidak Hadir</h4>
                    <Row>
                        <Col span={8}>
                            <div style={{
                                padding: 10,
                                marginRight: 5,
                                textAlign: 'center'
                            }}>
                                <b style={{ color: '#4682B4', fontSize: 18 }}>{total_absent}</b>
                                <div style={{ color: "#444444" }}>Absen</div>
                            </div>
                        </Col>
                        <Col span={8}>
                            <div style={{
                                padding: 10,
                                marginRight: 5,
                                textAlign: 'center'
                            }}>
                                <b style={{ color: '#4682B4', fontSize: 18 }}>{total_no_check_in}</b>
                                <div style={{ color: "#444444" }}>No Clock In</div>
                            </div>
                        </Col>
                        <Col span={8}>
                            <div style={{
                                padding: 10,
                                marginRight: 5,
                                textAlign: 'center'
                            }}>
                                <b style={{ color: '#4682B4', fontSize: 18 }}>{total_no_check_out}</b>
                                <div style={{ color: "#444444" }}>No Clock Out</div>
                            </div>
                        </Col>
                    </Row>
                </Col>
                {/* < Divider
                    type="vertical" style={{ height: "100px", backgroundColor: "#F2F2F2" }}
                />
                <Col span={6}>
                    <h4 style={{ textAlign: "center", margin: 0, padding: 0, marginTop: 10 }}>Away</h4>
                    <Row>
                        <Col span={12}>
                            <div style={{
                                padding: 10,
                                marginRight: 5,
                                textAlign: 'center'
                            }}>
                                <b style={{ color: '#4682B4', fontSize: 18 }}>274</b>
                                <div style={{ color: "#444444" }}>Hari Libur</div>
                            </div>
                        </Col>
                        <Col span={12}>
                            <div style={{
                                padding: 10,
                                marginRight: 5,
                                textAlign: 'center'
                            }}>
                                <b style={{ color: '#4682B4', fontSize: 18 }}>13</b>
                                <div style={{ color: "#444444" }}>Time Off</div>
                            </div>
                        </Col>
                    </Row>
                </Col> */}
            </Row>
            <Table
                size="middle"
                columns={columns}
                dataSource={data}
                pagination={page.pagination}
                loading={loading}
                onChange={handleTableChange}
            />
            <Modal title="Data Attendance" open={isModal} onOk={form.submit} onCancel={() => closeModal()} okText="Simpan" >
                <Form form={form} name="form" layout="vertical" onFinish={onFinish}>
                    <Form.Item
                        name="name"
                        label="Nama Divisi"
                        rules={[
                            {
                                required: true,
                                message: 'Nama Divisi kosong!'
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="desc"
                        label="Keterangan"
                        rules={[
                            {
                                required: false,
                                message: 'Keterangan kosong!'
                            },
                        ]}
                    >
                        <Input.TextArea />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
}


export default Attendance;