
import { Alert, Button, Col, Form, Input, Modal, Row, Select, Space, Table, Upload } from 'antd';
import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import { EditOutlined, DeleteOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { apiCall } from '../../API/RestApi';
import { modalError, modalSuccess } from '../../Component/Modal/ModalLayout';
import { useSelector } from 'react-redux';
import Search from 'antd/es/input/Search';
import moment from 'moment';


function Kpi() {
    const token = useSelector((state) => state.user.token);
    const user = useSelector((state) => state.user.user);
    const [data, setData] = useState([]);
    const [users, setUsers] = useState([]);
    const [division, setDivision] = useState([]);
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(false);
    const [isModal, setIsModal] = useState(false);
    const [dataModal, setdataModal] = useState(null);
    const [form] = Form.useForm();
    const [page, setPage] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });
    const [isAlert, setIsAlert] = useState(false);
    const [textAlert, setTextAlert] = useState(false);

    // //Parameter
    const titlePage = 'Data KPI Karyawan';
    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            key: "no",
            render: (id, record, index) => { ++index; return (page.pagination.current - 1) * 10 + index },
        },
        {
            title: 'Judul',
            dataIndex: 'title',
            key: "title",
            render: (text, record) => {
                return record.title
            }
        },
        {
            title: 'Kode Karyawan',
            dataIndex: 'users_code',
            key: "users_code",
            render: (text, record) => {
                if (record.users !== null)
                    return record.users.users_code
                else
                    return "-";
            }
        },
        {
            title: 'Nama Karyawan',
            dataIndex: 'name',
            key: "name",
            render: (text, record) => {
                if (record.users !== null)
                    return record.users.name
                else
                    return "-";
            }
        },
        {
            title: 'Divisi',
            dataIndex: 'division',
            key: "division",
            render: (text, record) => {
                if (record.division !== null)
                    return record.division.name
                else
                    return "-";
            }
        },
        {
            title: 'Nilai KPI',
            dataIndex: 'title',
            key: "title",
            render: (text, record) => {
                return record.kpi_value
            }
        },
        {
            title: 'Tanggal Buat',
            dataIndex: 'created_at',
            key: "created_at",
            render: (text, record) => {
                if (record.created_at !== "")
                    return (<p>{moment(record.created_at).format('YYYY-MM-DD HH:mm:ss')}</p>);
                else
                    return "-";
            }
        },
        {
            title: 'Tanggal Ubah',
            dataIndex: 'updated_at',
            key: "updated_at",
            render: (text, record) => {
                if (record.updated_at !== "")
                    return (<p>{moment(record.updated_at).format('YYYY-MM-DD HH:mm:ss')}</p>);
                else
                    return "-";
            }
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => {
                var usr = JSON.parse(user);
                if (usr.users_id === record.users_id)
                    return (
                        <>
                            <Button type="primary" style={{ marginRight: 10 }} onClick={() => openModal('edit', record)}>
                                <EditOutlined />
                                <b style={{ marginLeft: 5 }}>Ubah</b>
                            </Button>
                            <Button type="primary" danger onClick={() => modalDeleteData(record)}>
                                <DeleteOutlined />
                                <b style={{ marginLeft: 5 }}>Hapus</b>
                            </Button>
                        </>
                    );
                else
                    return "-";

            },
        },
    ];

    useEffect(() => {
        var usr = JSON.parse(user);
        getApi("GET", '/kpi?division=' + usr.division_id + '&search=' + search + '&page=' + page.pagination.current, 'kpi');
        getApi("GET", '/users?nopage=true', 'user');
        getApi("GET", '/division?nopage=true', 'division');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(page), isAlert]);

    const getApi = (method, url, type) => {
        setLoading(true);

        const dataReq = {
            method: method,
            url: url,
            data: {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            }
        };
        apiCall(dataReq).then((res) => {
            if (res.status === "success") {
                switch (type) {
                    case 'kpi':
                        setData(res.data.data);
                        setPage({
                            ...page,
                            pagination: {
                                ...page.pagination,
                                total: res.data.total_data
                            }
                        });
                        setLoading(false);
                        break;
                    case 'user':
                        var row = [];
                        for (let i = 0; i < res.data.data.length; i++) {
                            row.push({
                                value: JSON.stringify(res.data.data[i]),
                                label: res.data.data[i].name,
                            });
                        }
                        setUsers(row);
                        setLoading(false);
                        break;
                    case 'division':
                        var row = [];
                        for (let i = 0; i < res.data.data.length; i++) {
                            row.push({
                                value: res.data.data[i].division_id,
                                label: res.data.data[i].name,
                            });
                        }
                        setDivision(row);
                        setLoading(false);
                        break;

                    default:
                        setLoading(false);
                        break;
                }
            } else {
                setLoading(false);
            }
        }).catch((e) => {
            setLoading(false);
            console.log(e.message);
        })
    }

    const postApi = async (method, url, values) => {
        setLoading(true);
        setIsAlert(false);
        const dataReq = {
            method: method,
            url: url,
            data: {
                data: values,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: 'Bearer ' + token
                }
            }
        };
        await apiCall(dataReq).then((res) => {
            if (res.status === "success") {
                setdataModal(null);
                setLoading(false);
                setIsAlert(true);
                setTextAlert(res.message);
                closeModal();
            } else {
                setLoading(false);
                modalError(res.message);
            }
        }).catch((e) => {
            setLoading(false);
            console.log(e.message);
        })
    }

    const modalDeleteData = (v) => {
        Modal.confirm({
            title: 'NOTICE',
            content: 'Anda ingin menghapus data ini?',
            onOk: () => postApi('DELETE', '/kpi/' + v.kpi_id, null),
        });
    }

    const openModal = async (type, data) => {
        if (type === 'edit') {
            setdataModal(data)
            form.setFieldsValue({
                users_id: data.users_id,
                division_id: data.division_id,
                kpi_value: data.kpi_value,
                title: data.title,
                desc: data.desc,
            })
            setIsModal(true);
        } else {
            setIsModal(true);
        }
    }

    const onFinish = async (values) => {
        const formData = new FormData();

        for (const key in values) {
            if (values.hasOwnProperty(key)) {
                formData.append(key, values[key]);
            }
        }

        const file = values.file;
        if (!file) {
            console.log("File must be uploaded!");
            return;
        }

        formData.append('file', file.file);

        if (dataModal != null) {
            await postApi("PUT", '/kpi/' + dataModal.kpi_id, formData);
        } else {
            await postApi("POST", '/kpi', formData);
        }
    };

    const closeModal = () => {
        setdataModal(null);
        setIsModal(false);
        form.resetFields();
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setPage({
            pagination,
            filters,
            ...sorter,
        });
        if (pagination.pageSize !== page.pagination?.pageSize) {
            setData([]);
        }
    };


    const searchData = () => {
        setPage({
            pagination: {
                current: 1,
                pageSize: 10,
            },
        });
    }

    const onSelected = (ev) => {
        var user = JSON.parse(ev);
        form.setFieldValue('division_id', user.division_id)
        form.setFieldValue('users_id', user.users_id)
    }

    return (
        <div style={{ padding: '20px' }}>
            <Helmet>
                <title>{titlePage}</title>
            </Helmet>

            {isAlert && (
                <Alert
                    message={textAlert}
                    type="success"
                    banner
                    closable
                    afterClose={() => setIsAlert(false)}
                    style={{ marginBottom: 16 }}
                />
            )}

            <h3 style={{ marginBottom: '20px', fontSize: '24px' }}>KPI Karyawan</h3>

            {/* Row for Select (Division) and Search */}
            <Row gutter={16} style={{ marginBottom: '20px' }}>
                <Col span={5}>
                    <Select
                        showSearch
                        placeholder="Pilih Divisi"
                        optionFilterProp="children"
                        style={{ width: '100%' }}
                        filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input)}
                        filterSort={(optionA, optionB) =>
                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                        }
                        options={division}
                    />
                </Col>
                <Col span={5}>
                    <Search
                        placeholder="Pencarian"
                        allowClear
                        size="medium"
                        onChange={(e) => setSearch(e.target.value)}
                        style={{ width: '100%' }}
                    />
                </Col>
                <Col span={2}>
                    <Button type="primary" size="middle" onClick={() => searchData()}>
                        <b>Cari</b>
                    </Button>
                </Col>

                <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button type="primary" size="middle" onClick={() => openModal('add', null)}>
                        <PlusOutlined />
                        <b style={{ marginLeft: 5 }}>Tambah</b>
                    </Button>
                </Col>
            </Row>


            <Table
                size="middle"
                columns={columns}
                dataSource={data}
                pagination={page.pagination}
                loading={loading}
                onChange={handleTableChange}
                scroll={{ x: '100%' }} // For responsiveness
                style={{ marginTop: '20px' }}
            />

            <Modal
                title="Data KPI Karyawan"
                open={isModal}
                onOk={form.submit}
                onCancel={() => closeModal()}
                okText="Simpan"
                cancelText="Batal"
                centered
                width={700} // Adjust modal width
            >
                <Form
                    form={form}
                    name="kpiForm"
                    layout="vertical"
                    onFinish={onFinish}
                >
                    <Form.Item
                        name="users_id"
                        label="Pilih Karyawan"
                        rules={[{ required: true, message: 'Nama Karyawan tidak boleh kosong!' }]}
                    >
                        <Select
                            showSearch
                            placeholder="Pilih Karyawan"
                            optionFilterProp="children"
                            filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input)}
                            filterSort={(optionA, optionB) =>
                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                            }
                            options={users}
                            onSelect={(ev) => onSelected(ev)}
                        />
                    </Form.Item>

                    <Form.Item
                        name="division_id"
                        label="Pilih Divisi"
                        rules={[{ required: true, message: 'Divisi tidak boleh kosong!' }]}
                    >
                        <Select
                            showSearch
                            placeholder="Pilih Divisi"
                            optionFilterProp="children"
                            filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input)}
                            filterSort={(optionA, optionB) =>
                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                            }
                            options={division}
                        />
                    </Form.Item>

                    <Form.Item
                        name="title"
                        label="Judul"
                        rules={[{ required: true, message: 'Judul tidak boleh kosong!' }]}
                    >
                        <Input placeholder="Masukkan Judul" />
                    </Form.Item>

                    <Form.Item
                        name="desc"
                        label="Deskripsi"
                    >
                        <Input.TextArea placeholder="Masukkan Deskripsi" rows={4} />
                    </Form.Item>

                    <Form.Item
                        name="kpi_value"
                        label="Nilai KPI"
                        rules={[{ required: true, message: 'Nilai KPI tidak boleh kosong!' }]}
                    >
                        <Input type="number" placeholder="Masukkan Nilai KPI" />
                    </Form.Item>

                    <Form.Item
                        name="file"
                        label="Upload File"
                    >
                        <Upload
                            name="file"
                            multiple={false}
                            accept=".xlsx,.xls,.csv" // Specify acceptable file types
                            beforeUpload={() => false} // Prevent auto upload
                        >
                            <Button icon={<UploadOutlined />}>Upload File</Button>
                        </Upload>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
}


export default Kpi;