import { HomeOutlined, ClockCircleOutlined, MenuOutlined, BookOutlined,PoundOutlined, InboxOutlined, FileTextOutlined, GatewayOutlined, FileDoneOutlined,FileOutlined, TeamOutlined, SettingOutlined, AppstoreOutlined, FormOutlined } from '@ant-design/icons';
import { Menu, Layout, Button } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
const { Sider } = Layout;


function getItem(label, key, icon, children, type) {
    return {
        key,
        icon,
        children,
        label,
        type,
    };
}

function MenuSideBars() {

    const selector = useSelector((state) => state.user);
    const navigate = useNavigate();
    const [collapsed, setCollapsed] = useState(false); // State untuk mengelola keterbukaan Sider
    const [menuItems, setMenuItems] = useState([]);

    useEffect(() => {
        var user_role = JSON.parse(selector.menu);
        var menu = [];
        
        // Sort the main menu based on the sequence value
        user_role.role_menu.sort((a, b) => a.menu.sequence - b.menu.sequence);
        
        for (let i = 0; i < user_role.role_menu.length; i++) {
            var mn = user_role.role_menu[i];
            var sub_mn = [];
        
            // Check if there are submenus and filter out null values before sorting
            if (mn.role_submenu.length) {
                // Filter out submenus that are null, then sort by sequence
                let validSubMenus = mn.role_submenu.filter(sub => sub.submenu !== null);
        
                validSubMenus.sort((a, b) => a.submenu.sequence - b.submenu.sequence);
        
                // Create submenu items
                for (let i2 = 0; i2 < validSubMenus.length; i2++) {
                    var sub = validSubMenus[i2];
                    sub_mn.push(getItem(sub.submenu.submenu, sub.submenu.link));
                }
            }
        
            // Add main menu items (with or without submenus)
            if (sub_mn.length > 0) {
                menu.push(getItem(mn.menu.menu, mn.menu.link, getIcon(mn.menu.icon), sub_mn));
            } else {
                menu.push(getItem(mn.menu.menu, mn.menu.link, getIcon(mn.menu.icon)));
            }
        }
        
        // Set the sorted menu items
        setMenuItems(menu);
        
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    const getIcon = (icon_name) => {
        switch (icon_name) {
            case 'home':
                return <HomeOutlined />;
            case 'form':
                return <FormOutlined />
            case 'paper':
                return <FileOutlined />
            case 'approval':
                return <FileDoneOutlined />;
            case 'report':
                return <FileTextOutlined />;
            case 'master':
                return <AppstoreOutlined />;
            case 'book':
                return <BookOutlined />;
            case 'team':
                return <TeamOutlined />;
            case 'settings':
                return <SettingOutlined />;
            case 'membership':
                return <TeamOutlined />
            case 'penjualan':
                return <InboxOutlined />;
            case 'meeting':
                return <GatewayOutlined />;
            case 'benefit':
                return <PoundOutlined />
            case 'absen':
                return <ClockCircleOutlined />
            case 'kpi':
                return <FileDoneOutlined />
        }
    }

    const toggleCollapsed = () => {
        setCollapsed(!collapsed);
    }

    return (
        <Sider
            // collapsible
            collapsed={collapsed}
            onCollapse={setCollapsed}
            width={200}
            collapsedWidth={80} // Lebar Sider saat diperkecil
            style={{
                backgroundColor: '#ffffff', // Latar belakang putih
                boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)', // Optional: tambahkan shadow
            }}
        >
            <div className="logo" />
            <Button
                type="text"
                icon={collapsed ? <MenuOutlined style={{ color: '#1890ff' }} /> : <MenuOutlined style={{ color: '#1890ff' }} />}
                onClick={toggleCollapsed}
                style={{ marginLeft:24,marginTop:10,textAlign: 'center' }}
            />
            <Menu
                mode="inline"
                defaultSelectedKeys={['dashboard']}
                style={{
                    height: '100%',
                    borderRight: 0,
                    fontSize: 11,
                    backgroundColor: '#ffffff', // Optional: latar belakang putih untuk Menu
                }}
                items={menuItems}
                onClick={({ key }) => navigate('/' + key)}
            />
        </Sider>
    );
}

export default MenuSideBars;